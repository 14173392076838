import { HttpResponse } from "@capacitor/core";
import { IonAlert, IonButton, IonText, IonTextarea } from "@ionic/react";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { SavedUser } from "../../hooks/LoginHook";
import { JIBI_BASE_URL, POST } from "../../util/ApiClient";
import { UserContext } from "../../util/BetterDatesApp";
import { handleError } from "../../util/error";
import { defaultToastState } from "../../util/IonicExt";

export default function DeleteAccountModal() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [deleteReason, setDeleteReason] = useState("");
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [inProgress, setInProgress] = useState(false);

  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext?.userState.savedUser;

  return (
    <main className="flex h-full w-full flex-col justify-between px-4">
      <div className="flex h-full w-full flex-col gap-4">
        <IonText className="pt-4">
          Please tell us why you&apos;re deleting your account and how we can
          improve.
        </IonText>
        <div className="flex w-full">
          <div className="h-full w-full rounded-lg bg-gray-200 px-2 pb-4">
            <IonTextarea
              placeholder="Please type here..."
              autofocus={true}
              color={"dark"}
              onIonInput={(e) => {
                setDeleteReason(e.detail.value || "");
              }}
              className="h-full w-full overflow-y-scroll no-scrollbar"
            />
          </div>
        </div>
      </div>
      <div className="flex h-20 w-full flex-row justify-end">
        <IonButton
          className="w-37 h-9"
          shape="round"
          color="danger"
          size="small"
          disabled={inProgress}
          onClick={(e) => {
            e.preventDefault();
            setInProgress(true);
            postDeleteRequest(savedUser, deleteReason)
              .then((result) => {
                if (result.status !== 200) {
                  return Promise.reject(result);
                }
                setIsDeleteConfirmationOpen(true);
              })
              .catch((e) => handleError(e, userContext))
              .finally(() => {
                setInProgress(false);
              });
          }}
        >
          Delete my account
        </IonButton>
      </div>
      <IonAlert
        isOpen={isDeleteConfirmationOpen}
        id="deleteAlert"
        header="Request submitted"
        message="Your delete account request was submitted. It can take up to 5 business days for us to process your request. You're always welcome to come back to our platform."
        buttons={[
          {
            text: "BYE",
            role: "cancel",
          },
        ]}
        onDidDismiss={() => {
          setIsDeleteConfirmationOpen(false);
          userContext?.toastHook.toast({
            ...defaultToastState(),
            message: `Bye ${savedUser?.user.name || ""} 😔`,
            isOpen: true,
          });
          userContext?.loginHook.logoutUser();
          history.push("/");
        }}
      />
    </main>
  );
}

function postDeleteRequest(
  savedUser: SavedUser,
  deleteReason: string,
): Promise<HttpResponse> {
  return POST({
    url: `${JIBI_BASE_URL}/collections/users_delete_requests/records`,
    headers: {
      Authorization: savedUser.token,
    },
    body: {
      user: savedUser.user.id,
      reason: deleteReason,
    },
  });
}
