import { HttpResponse } from "@capacitor/core";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import {
  IonButton,
  IonChip,
  IonContent,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonText,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Religion from "../../../data/religion";
import { GET, JIBI_BASE_URL, PATCH } from "../../../util/ApiClient";
import { UserContext } from "../../../util/BetterDatesApp";
import { handleError } from "../../../util/error";

export default function ReligionInformation() {
  const userContext = useContext(UserContext);
  const history = useHistory();
  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [state, setState] = useState<ReligionStateType>({ loading: true });
  const [religion, setReligion] = useState<Religion | undefined>(undefined);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    fetchReligions(savedUser.token)
      .then((result) => {
        if (result.status !== 200) {
          return Promise.reject(result);
        }
        const religions = result.data.items as Religion[];
        setState({ loading: false, religions: religions });
      })
      .catch((e) => handleError(e, userContext));
  }, []);

  useEffect(() => {
    if (state.loading) {
      return;
    }
    const religions = state.religions;
    const userReligionId = savedUser.user.religion;
    const usersReligion = religions.find((value) => {
      return value.id === userReligionId;
    });
    setReligion(usersReligion);
  }, [state]);

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <>
            <header className="flex flex-col gap-8 px-8 py-8">
              <div>
                <IonChip
                  onClick={(e) => {
                    e.preventDefault();
                    history.goBack();
                  }}
                >
                  <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
                  <IonLabel>Back</IonLabel>
                </IonChip>
              </div>
              <div className="flex w-full flex-row items-start">
                <IonText className="text-2xl font-bold">
                  What&apos;s your religion?
                </IonText>
              </div>
            </header>
            <main className="h-full w-full">
              <div className="flex h-full w-full flex-col justify-between px-8 pb-8">
                {state.loading ? (
                  <div className="flex h-full w-full items-center justify-center">
                    <IonText>Loading...</IonText>
                  </div>
                ) : (
                  <IonContent
                    className="scrollable text-gray-800"
                    scrollY={false}
                  >
                    <IonRadioGroup
                      value={religion}
                      onIonChange={(e) => {
                        setReligion(e.detail.value);
                      }}
                    >
                      <div className="scrollable flex h-full w-full flex-col gap-10 overflow-y-scroll no-scrollbar">
                        {state.religions.map((value) => (
                          <div className="scrollable" key={value.id}>
                            <IonRadio
                              value={value}
                              className="scrollable w-full"
                              labelPlacement="end"
                              color="dark"
                              justify="start"
                            >
                              <IonText className="h-10 w-full">
                                {value.religion}
                              </IonText>
                            </IonRadio>
                          </div>
                        ))}
                      </div>
                    </IonRadioGroup>
                  </IonContent>
                )}
                <div className="flex w-full flex-row justify-end">
                  <IonButton
                    className={"mt-8 h-9 w-20"}
                    shape={"round"}
                    color={"dark"}
                    disabled={religion === undefined || inProgress}
                    size={"small"}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!religion) return;
                      setInProgress(true);
                      patchReligion(religion, savedUser.token)
                        .then((result) => {
                          if (result.status !== 200) {
                            return Promise.reject(result);
                          }
                          const user = {
                            ...savedUser.user,
                            religion: religion.id,
                          };
                          return userContext?.loginHook.saveUser({
                            ...savedUser,
                            user: user,
                          });
                        })
                        .then(() => history.replace("/preferences/religion"))
                        .catch((e) => handleError(e, userContext))
                        .finally(() => {
                          setInProgress(false);
                        });
                    }}
                  >
                    Next
                  </IonButton>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>
    </IonPage>
  );
}

interface ReligionState {
  loading: boolean;
}

interface ReligionStateLoaded extends ReligionState {
  loading: false;
  religions: Religion[];
}

interface ReligionStateLoading extends ReligionState {
  loading: true;
}

type ReligionStateType = ReligionStateLoading | ReligionStateLoaded;

function fetchReligions(token: string): Promise<HttpResponse> {
  return GET({
    url: `${JIBI_BASE_URL}/collections/religions_view/records`,
    headers: {
      Authorization: token,
    },
  });
}

function patchReligion(
  religion: Religion,
  token: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/religion`,
    headers: {
      Authorization: token,
    },
    body: {
      religion: religion.id,
    },
  });
}
