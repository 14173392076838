import UserContextHook from "../hooks/UserContextHook";
import { ERROR_DEFAULT } from "../i18n/static";
import { isHttpResponse } from "./ApiClient";
import { defaultToastState } from "./IonicExt";

export const CAUSE_INFORMATIVE = "[INFORMATIVE]";

export function informativeError(message: string): Error {
  return new Error(`${CAUSE_INFORMATIVE} ${message}`);
}

export interface ClientError {
  data: {
    client: {
      code: string;
      message: string;
    };
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function responseHasClientError(input: any): input is ClientError {
  const client = input?.data?.client;
  return client && client?.code && client?.message;
}

export function handleError(error: Error, userContext: UserContextHook) {
  let toastMessage: string = ERROR_DEFAULT;
  if (isHttpResponse(error)) {
    if (responseHasClientError(error.data)) {
      toastMessage = error.data.data.client.message;
    }
    userContext?.errorHook.logError("I", error);
  } else {
    userContext?.errorHook.logError("E", error);
  }
  userContext?.toastHook.toast({
    ...defaultToastState(),
    message: toastMessage,
    isOpen: true,
  });
}
