import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { IonPage, IonText } from "@ionic/react";

export default function MaintenanceMode() {
  return (
    <IonPage className="flex items-center justify-center">
      <div className="h-full w-full max-w-md">
        <main className="flex h-full w-full items-center justify-center px-8">
          <div className="flex flex-col">
            <WrenchScrewdriverIcon className="h-8 w-8 stroke-black stroke-1" />
            <IonText className="mt-4 text-xl font-bold">
              We&apos;re upgrading your user experience.
            </IonText>
            <IonText>
              Please comeback in a minute while we&apos;re working hard to make
              this happen.
            </IonText>
          </div>
        </main>
      </div>
    </IonPage>
  );
}
