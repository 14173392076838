import { IonButton, IonInput, IonItem, IonLabel, IonText } from "@ionic/react";
import { useContext, useMemo, useState } from "react";
import OTPInput from "../../../components/OTPInput";
import {
  patchUserEmailVerification,
  postUserEmailVerification,
} from "../../../datasource/user-datasource";
import { UserContext } from "../../../util/BetterDatesApp";
import { handleError } from "../../../util/error";
import { defaultToastState } from "../../../util/IonicExt";
import { createValidEmailRegEx } from "../../../util/regex-ext";

export default function EmailInformation(props: { onNext: () => void }) {
  const userContext = useContext(UserContext);
  const userState = userContext?.userState;
  const loggedIn = userState?.loggedIn;
  if (!loggedIn) {
    props.onNext();
    return <></>;
  }
  const [enteredEmailAddress, setEnteredEmailAddress] = useState("");
  const [isVerificationStep, setIsVerificationStep] = useState(false);
  return (
    <div className="flex h-full w-full flex-col justify-between px-8 pb-4">
      {(isVerificationStep && (
        <CodeInputStep
          email={enteredEmailAddress}
          onNext={() => {
            props.onNext();
          }}
          onBack={() => {
            setIsVerificationStep(false);
          }}
        />
      )) || (
        <EmailInputStep
          onNext={(email) => {
            setEnteredEmailAddress(email);
            setIsVerificationStep(true);
          }}
        />
      )}
    </div>
  );
}

function EmailInputStep(props: { onNext: (email: string) => void }) {
  const userContext = useContext(UserContext);
  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [emailInput, setEmailInput] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const emailRegEx = useMemo(() => {
    return createValidEmailRegEx();
  }, []);
  return (
    <div className="flex h-full w-full flex-col justify-between pb-4">
      <div className="flex w-full flex-col">
        <IonInput
          placeholder="email@example.com"
          type={"email"}
          autofocus={true}
          className="force-dark text-xl"
          color="dark"
          value={emailInput}
          onIonInput={(e) => {
            const newInput = e.detail.value as string;
            setEmailInput(newInput);
          }}
        />
      </div>
      <div className="flex h-14 w-full flex-row justify-end">
        <IonButton
          className={"mt-8 h-9 w-20"}
          shape={"round"}
          color={"dark"}
          disabled={
            emailInput.length === 0 ||
            !emailRegEx.test(emailInput) ||
            inProgress
          }
          size={"small"}
          onClick={(e) => {
            e.preventDefault();
            setInProgress(true);
            postUserEmailVerification(savedUser, emailInput)
              .then((result) => {
                if (result.status !== 200) {
                  return Promise.reject(result);
                }
              })
              .then(() => props.onNext(emailInput))
              .catch((e) => handleError(e, userContext))
              .finally(() => {
                setInProgress(false);
              });
          }}
        >
          Next
        </IonButton>
      </div>
    </div>
  );
}

function CodeInputStep(props: {
  email: string;
  onNext: () => void;
  onBack: () => void;
}) {
  const userContext = useContext(UserContext);
  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [codeInput, setCodeInput] = useState("");
  const [inProgress, setInProgress] = useState(false);
  return (
    <div className="flex h-full w-full flex-col justify-between pb-4">
      <div className="flex w-full flex-col gap-8">
        <IonText>
          Please enter the code we have sent to:
          <br />
          <b>{props.email}</b>
        </IonText>
        <div className="flex flex-row items-center justify-center">
          <OTPInput value={codeInput} onChange={setCodeInput} />
        </div>
        <div className="flex flex-row justify-end">
          <IonButton
            className={"h-9 w-20"}
            shape={"round"}
            color={"dark"}
            disabled={inProgress}
            size={"small"}
            fill={"clear"}
            onClick={(e) => {
              e.preventDefault();
              setInProgress(true);
              postUserEmailVerification(savedUser, props.email)
                .then((result) => {
                  if (result.status !== 200) {
                    return Promise.reject(result);
                  }
                })
                .then(() => {
                  userContext?.toastHook.toast({
                    ...defaultToastState(),
                    message: "Sent a new code ✅",
                    isOpen: true,
                  });
                })
                .catch((e) => handleError(e, userContext))
                .finally(() => {
                  setInProgress(false);
                });
            }}
          >
            Resend
          </IonButton>
          <IonButton
            className="h-9 w-20"
            shape="round"
            color="dark"
            disabled={codeInput.length < 6 || inProgress}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              setInProgress(true);
              patchUserEmailVerification(savedUser, props.email, codeInput)
                .then((result) => {
                  if (result.status !== 200) {
                    return Promise.reject(result);
                  }
                  const user = {
                    ...savedUser.user,
                    email: result.data.email,
                  };
                  return userContext?.loginHook.saveUser({
                    ...savedUser,
                    user: user,
                  });
                })
                .then(() => props.onNext())
                .catch((e) => handleError(e, userContext))
                .finally(() => {
                  setInProgress(false);
                });
            }}
          >
            Verify
          </IonButton>
        </div>
        <IonItem
          className={"mt-3"}
          lines={"none"}
          color="light"
          onClick={() => {
            props.onBack();
          }}
        >
          <IonLabel>Change email address</IonLabel>
        </IonItem>
      </div>
    </div>
  );
}
