import React from "react";
import { createRoot } from "react-dom/client";
import BetterDatesApp from "./util/BetterDatesApp";
import * as Sentry from "@sentry/react";
import { NativeWeb } from "nativebridge/dist/esm/web";
import { CAUSE_INFORMATIVE } from "./util/error";

new NativeWeb()
  .getAppMetaData()
  .then((appMetaData) => {
    Sentry.init({
      dsn: "https://59aa28e263033835a9c22a30a5c9b521@o4507653157158912.ingest.us.sentry.io/4507653171183616",
      enabled: import.meta.env.MODE === "production",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/web\.betterdates\.app/,
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: (import.meta.env.PROD && "production") || "development",
      release: `betterdates.capacitor@${appMetaData.version.versionCode}`,
      ignoreErrors: [CAUSE_INFORMATIVE],
    });
  })
  .finally(() => {
    const container = document.getElementById("root");
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = createRoot(container!);
    root.render(
      <React.StrictMode>
        <BetterDatesApp />
      </React.StrictMode>,
    );
  });
