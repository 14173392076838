export function cmToFeetInches(cm: number): string {
  const inches = cm / 2.54;
  const feet = Math.floor(inches / 12);
  const remainingInches = Math.round(inches % 12);

  if (remainingInches === 12) {
    return `${feet + 1}'0"`;
  }

  return `${feet}'${remainingInches}"`;
}
