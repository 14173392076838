import { HttpResponse } from "@capacitor/core";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import {
  IonButton,
  IonCheckbox,
  IonChip,
  IonContent,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import RegistrationStepper from "../../../components/RegistrationStepper";
import Gender from "../../../data/Gender";
import { GET, JIBI_BASE_URL, PATCH } from "../../../util/ApiClient";
import { UserContext } from "../../../util/BetterDatesApp";
import { handleError } from "../../../util/error";

export default function GenderPreferences() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const loggedIn = userContext?.userState.loggedIn;
  if (!loggedIn) {
    return <></>;
  }
  const savedUser = userContext?.userState.savedUser;
  const [state, setState] = useState<GenderStateType>({ loading: true });
  const [genders, setGenders] = useState<Gender[]>([]);
  useEffect(() => {
    fetchGenders(savedUser.token)
      .then((result) => {
        if (result.status !== 200) {
          return Promise.reject(result);
        }
        const possibleGenders = result.data.items as Gender[];
        setState({ loading: false, genders: possibleGenders });
      })
      .catch((e) => handleError(e, userContext));
  }, []);

  useEffect(() => {
    if (state.loading) return;
    if (!savedUser.user.matchPreference?.gender) return;
    if (savedUser.user.matchPreference.gender.length < 1) return;
    const preselectedGenderIds =
      savedUser.user.matchPreference?.gender?.map((gender) => gender.id) || [];
    state.genders
      .filter((value) => {
        return preselectedGenderIds?.includes(value.id);
      })
      .forEach((selectedGender) => {
        setGenders((prevState) => {
          return prevState.includes(selectedGender)
            ? prevState
            : [...prevState, selectedGender];
        });
      });
  }, [state]);

  const updateGenderPreferences = () => {
    patchGenderPreferences(
      genders.map((gender) => gender.id),
      savedUser.token,
    )
      .then((result) => {
        if (result.status !== 200) {
          return Promise.reject(result);
        }
        const newMatchPreferences = {
          ...savedUser.user.matchPreference,
          ...result.data.preferences,
        };
        const user = {
          ...savedUser.user,
          matchPreference: newMatchPreferences,
        };
        return userContext?.loginHook?.saveUser({
          ...savedUser,
          user: user,
        });
      })
      .then(() => history.goBack())
      .catch((e) => handleError(e, userContext));
  };

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex flex-col gap-8 px-8 py-8">
            <div>
              <IonChip
                onClick={(e) => {
                  e.preventDefault();
                  history.replace("/gender");
                }}
              >
                <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
                <IonLabel>Back</IonLabel>
              </IonChip>
            </div>
            <RegistrationStepper steps={2} completedUntil={1} />
            <IonText className="text-2xl font-bold">
              Which gender(s) are you interested in dating?
            </IonText>
          </header>
          <main className="h-full w-full">
            <div className="flex h-full w-full flex-col justify-between px-8 pb-8">
              {state.loading ? (
                <div className="flex h-full w-full items-center justify-center">
                  <IonText>Loading...</IonText>
                </div>
              ) : (
                <IonContent
                  className="scrollable text-gray-800"
                  scrollY={false}
                >
                  <div className="scrollable flex h-full w-full flex-col gap-10 overflow-y-scroll no-scrollbar">
                    {state.genders.map((value) => (
                      <div className="scrollable" key={value.id}>
                        <IonCheckbox
                          value={value}
                          className="scrollable"
                          labelPlacement="end"
                          checked={genders.includes(value)}
                          color="dark"
                          onIonChange={(e) => {
                            if (e.detail.checked) {
                              setGenders((prevState) => {
                                return [...prevState, e.detail.value];
                              });
                            } else {
                              setGenders((prevState) => {
                                return prevState.filter((value) => {
                                  return value.id !== e.detail.value.id;
                                });
                              });
                            }
                          }}
                        >
                          {value.name}
                        </IonCheckbox>
                      </div>
                    ))}
                  </div>
                </IonContent>
              )}
              <div className="flex w-full flex-row justify-end">
                <IonButton
                  className={"mt-8 h-9 w-20"}
                  shape={"round"}
                  color={"dark"}
                  disabled={genders.length === 0}
                  size={"small"}
                  onClick={(e) => {
                    e.preventDefault();
                    updateGenderPreferences();
                  }}
                >
                  Save
                </IonButton>
              </div>
            </div>
          </main>
        </div>
      </div>
    </IonPage>
  );
}

interface GenderState {
  loading: boolean;
}

interface GenderStateLoaded extends GenderState {
  loading: false;
  genders: Gender[];
}

interface GenderStateLoading extends GenderState {
  loading: true;
}

type GenderStateType = GenderStateLoading | GenderStateLoaded;

function fetchGenders(token: string): Promise<HttpResponse> {
  return GET({
    url: `${JIBI_BASE_URL}/collections/genders_view/records`,
    headers: {
      Authorization: token,
    },
  });
}

function patchGenderPreferences(
  genders: string[],
  token: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/preferences/gender`,
    headers: {
      Authorization: token,
    },
    body: {
      gender: genders,
    },
  });
}
