import { HttpResponse } from "@capacitor/core";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import {
  IonButton,
  IonChip,
  IonContent,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonText,
} from "@ionic/react";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import RegistrationStepper from "../../../components/RegistrationStepper";
import { JIBI_BASE_URL, PATCH } from "../../../util/ApiClient";
import { UserContext } from "../../../util/BetterDatesApp";
import { handleError } from "../../../util/error";

export default function HeightPreferences() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const loggedIn = userContext?.userState.loggedIn;
  if (!loggedIn) {
    return <></>;
  }
  const savedUser = userContext?.userState.savedUser;
  const [selectedHeight, setSelectedHeight] = useState<string>(
    savedUser.user.matchPreference?.height || "",
  );
  const [inProgress, setInProgress] = useState(false);

  const updateHeightPreferences = () => {
    setInProgress(true);
    patchHeightPreferences(selectedHeight, savedUser.token)
      .then((result) => {
        if (result.status !== 200) {
          return Promise.reject(result);
        }
        const newMatchPreferences = {
          ...savedUser.user.matchPreference,
          ...result.data.preferences,
        };
        const user = {
          ...savedUser.user,
          matchPreference: newMatchPreferences,
        };
        return userContext?.loginHook?.saveUser({
          ...savedUser,
          user: user,
        });
      })
      .then(() => history.goBack())
      .catch((e) => handleError(e, userContext))
      .finally(() => {
        setInProgress(false);
      });
  };

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex flex-col gap-8 px-8 py-8">
            <div>
              <IonChip
                onClick={(e) => {
                  e.preventDefault();
                  history.replace("/height");
                }}
              >
                <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
                <IonLabel>Back</IonLabel>
              </IonChip>
            </div>
            <RegistrationStepper steps={2} completedUntil={1} />
            <IonText className="text-2xl font-bold">
              What&apos;s your height preference?
            </IonText>
          </header>
          <main className="h-full w-full">
            <div className="flex h-full w-full flex-col justify-between px-8 pb-8">
              <IonContent className="scrollable text-gray-800" scrollY={false}>
                <IonRadioGroup
                  value={selectedHeight}
                  onIonChange={(e) => setSelectedHeight(e.detail.value)}
                >
                  <div className="scrollable flex h-full w-full flex-col gap-10 overflow-y-scroll no-scrollbar">
                    <div className="flex items-center">
                      <IonRadio
                        value="shorter"
                        color="dark"
                        labelPlacement="end"
                        className="w-full"
                        justify="start"
                      >
                        <IonText className="h-10 w-full">
                          I want my date to be <strong>shorter</strong> than me
                        </IonText>
                      </IonRadio>
                    </div>
                    <div className="flex items-center">
                      <IonRadio
                        value="taller"
                        color="dark"
                        labelPlacement="end"
                        className="w-full"
                        justify="start"
                      >
                        <IonText className="h-10 w-full">
                          I want my date to be <strong>taller</strong> than me
                        </IonText>
                      </IonRadio>
                    </div>
                    <div className="flex items-center">
                      <IonRadio
                        value="not-important"
                        color="dark"
                        labelPlacement="end"
                        className="w-full"
                        justify="start"
                      >
                        <IonText className="h-10 w-full">
                          It doesn&apos;t matter
                        </IonText>
                      </IonRadio>
                    </div>
                  </div>
                </IonRadioGroup>
              </IonContent>
              <div className="flex w-full flex-row justify-end">
                <IonButton
                  className="mt-8 h-9 w-20"
                  shape="round"
                  color="dark"
                  size="small"
                  disabled={selectedHeight.length === 0 || inProgress}
                  onClick={(e) => {
                    e.preventDefault();
                    updateHeightPreferences();
                  }}
                >
                  Save
                </IonButton>
              </div>
            </div>
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function patchHeightPreferences(
  height: string,
  token: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/preferences/height`,
    headers: {
      Authorization: token,
    },
    body: {
      height: height,
    },
  });
}
