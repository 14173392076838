import { IonPage, IonText } from "@ionic/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import RegistrationStepper from "../../../components/RegistrationStepper";
import { SavedUser } from "../../../hooks/LoginHook";
import { UserContext } from "../../../util/BetterDatesApp";
import {
  isAgeValid,
  isAvatarValid,
  isEmailValid,
  isNameValid,
  isProfileComplete,
  ProfileValidator,
} from "../../../validation/ProfileValidation";
import AgeInformation from "./AgeInformation";
import AvatarInformation from "./AvatarInformation";
import EmailInformation from "./EmailInformation";
import NameInformation from "./NameInformation";
import Welcome from "./Welcome";

export default function InformationFlow() {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const userState = userContext?.userState;
  const loggedIn = userState?.loggedIn;
  if (!loggedIn) {
    history.replace("/");
    return <></>;
  }
  const savedUser = userState?.savedUser;
  const [step, setStep] = useState(0);
  const [state, setState] = useState<InformationFlowStateType>({
    loading: true,
  });
  const informationSteps = useMemo(() => getInformationSteps(), []);
  useEffect(() => {
    if (step > informationSteps.length - 1) {
      userContext?.loginHook
        ?.saveUser({
          ...savedUser,
          isProfileComplete: isProfileComplete(savedUser.user),
        })
        .finally(() => {
          location.reload();
        });
    } else {
      const informationStep = informationSteps[step];
      if (informationStep.validator()(savedUser.user).valid) {
        setStep((prevState) => prevState + 1);
      } else {
        setState({ loading: false, step: informationStep });
      }
    }
  }, [step]);
  const stepUp = () => {
    setStep(step + 1);
  };
  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          {state.loading ? (
            <>
              <IonText>Loading...</IonText>
            </>
          ) : (
            <>
              <header className="flex flex-col items-center gap-8 px-8 py-8">
                {step > 0 && (
                  <RegistrationStepper
                    steps={informationSteps.length - 1}
                    completedUntil={step - 1}
                  />
                )}
                <div className="flex w-full flex-row items-start">
                  <IonText className="text-2xl font-bold">
                    {state.step.title(savedUser)}
                  </IonText>
                </div>
              </header>
              <main className="h-full w-full">
                {state.step.component(stepUp)}
              </main>
            </>
          )}
        </div>
      </div>
    </IonPage>
  );
}

function getInformationSteps(): InformationStep[] {
  return [
    {
      title: (savedUser) => {
        const name = savedUser.user.name ? ` ${savedUser.user.name}` : "";
        return `Welcome onboard${name}! 🎉`;
      },
      component: (onNext) => <Welcome onNext={onNext} />,
      validator: () => {
        return () => {
          return { valid: false };
        };
      },
    },
    {
      title: () => `What's your name?`,
      component: (onNext) => <NameInformation onNext={onNext} />,
      validator: () => isNameValid,
    },
    {
      title: (savedUser) => `When were you born ${savedUser.user.name}?`,
      component: (onNext) => <AgeInformation onNext={onNext} />,
      validator: () => isAgeValid,
    },
    {
      title: () => `Profile Picture`,
      component: (onNext) => <AvatarInformation onNext={onNext} />,
      validator: () => isAvatarValid,
    },
    {
      title: () => `Lastly, what's your email address?`,
      component: (onNext) => <EmailInformation onNext={onNext} />,
      validator: () => isEmailValid,
    },
  ];
}

interface InformationFlowState {
  loading: boolean;
}

interface InformationFlowStateLoading extends InformationFlowState {
  loading: true;
}

interface InformationFlowStateLoaded extends InformationFlowState {
  loading: false;
  step: InformationStep;
}

type InformationFlowStateType =
  | InformationFlowStateLoading
  | InformationFlowStateLoaded;

interface InformationStep {
  title: (savedUser: SavedUser) => string;
  component: (onNext: () => void) => JSX.Element;
  validator: () => ProfileValidator;
}
