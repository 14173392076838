import {
  CapacitorHttp,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@capacitor/core";

export const JIBI_BASE_URL = import.meta.env.VITE_JIBI_BASE_URL as string;
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL as string;
export const INSTANT_CONFIG_URL = import.meta.env
  .VITE_INSTANT_CONFIG_URL as string;

export interface HttpGetRequest {
  url: string;
  params?: HttpParams;
  headers?: HttpHeaders;
  webFetchExtra?: RequestInit;
}

export async function GET({
  url,
  params,
  headers,
  webFetchExtra,
}: HttpGetRequest) {
  return CapacitorHttp.get({
    webFetchExtra: webFetchExtra,
    url: url,
    params: params,
    shouldEncodeUrlParams: true,
    headers: headers,
    readTimeout: 30000,
    connectTimeout: 10000,
  });
}

export interface HttpPostRequest {
  url: string;
  params?: HttpParams;
  headers?: HttpHeaders;
  body?: unknown;
}

export async function POST({ url, params, headers, body }: HttpPostRequest) {
  const headersToPost: HttpHeaders = { ...headers };
  if (body && !headersToPost["Content-Type"]) {
    headersToPost["Content-Type"] = "application/json";
  }
  return CapacitorHttp.post({
    url: url,
    data: body,
    params: params,
    headers: headersToPost,
    shouldEncodeUrlParams: true,
    readTimeout: 30000,
    connectTimeout: 10000,
  });
}

export async function PATCH({ url, params, headers, body }: HttpPostRequest) {
  const headersToPost: HttpHeaders = { ...headers };
  if (body && !headersToPost["Content-Type"]) {
    headersToPost["Content-Type"] = "application/json";
  }
  return CapacitorHttp.patch({
    url: url,
    data: body,
    params: params,
    headers: headersToPost,
    shouldEncodeUrlParams: true,
    readTimeout: 30000,
    connectTimeout: 10000,
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isHttpResponse(input: any): input is HttpResponse {
  return input.status && input.url && input.headers;
}
