import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { IonButton, IonText } from "@ionic/react";
import tinycolor from "tinycolor2";
import Theme from "../data/Theme";
import Avatar from "./Avatar";

export default function MatchInfo(props: MatchInfoProps) {
  return (
    <div
      className={`relative h-[27rem] w-full md:h-[31rem] lg:h-[33rem] xl:h-[35rem]`}
    >
      {props.reported && (
        <div className="absolute z-10 h-full w-full backdrop-blur-sm">
          <div className="flex h-full w-full flex-col items-center justify-center">
            <IonText className="text-xl font-semibold">
              You have reported this user.
            </IonText>
          </div>
        </div>
      )}
      <div
        className="relative h-full w-full px-4 pt-4"
        style={{
          background: props.theme.c0000,
        }}
      >
        <div className="flex h-full w-full flex-col gap-4">
          <div className="h-[18rem] w-full bg-gray-500 md:h-[22rem] lg:h-[24rem] xl:h-[26rem]">
            <Avatar
              className="h-full w-full object-cover"
              src={props.avatar}
              alt=""
            />
          </div>
          <div className="flex flex-row items-baseline gap-2">
            <IonText
              className="font-mono text-2xl font-medium"
              style={{ color: props.theme.t0000 }}
            >
              {props.name}
            </IonText>
            <IonText className="font-mono" style={{ color: props.theme.t0001 }}>
              {props.age}
            </IonText>
          </div>
          <div className="h-10 w-full">
            <IonButton
              className="flex h-full w-full justify-between"
              style={{
                "--background": props.theme.c0100,
                "--background-activated": getButtonActivatedColor(
                  props.theme.c0100,
                ),
                "--color": props.theme.t0100,
                "--box-shadow": 0,
              }}
              onClick={props.onClick}
            >
              <div className="flex w-full flex-row justify-between">
                <span>Write them a letter now</span>
                <span>
                  <ArrowRightIcon className="h-4 w-4" />
                </span>
              </div>
            </IonButton>
          </div>
        </div>
      </div>
    </div>
  );
}

function getButtonActivatedColor(normalColor: string): string {
  const color = tinycolor(normalColor);
  if (color.isLight()) {
    return color.darken(8).toString();
  } else {
    return color.lighten(8).toString();
  }
}

export type ProfilePictureStateType = "innocent" | "error";

export interface ProfilePictureState {
  state: ProfilePictureStateType;
  transform: (to: () => ProfilePictureStateType) => void;
}

export interface MatchInfoState {
  profilePictureState: ProfilePictureState;
}

interface MatchInfoProps {
  state: MatchInfoState;
  name: string;
  age: number | undefined;
  avatar: string;
  theme: Theme;
  reported?: boolean;
  onClick: () => void;
}
