import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { useContext, useMemo } from "react";
import { Redirect, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import ListOfLetters from "./pages/letter/ListOfLetters";
import ReadLetter from "./pages/letter/ReadLetter";
import WriteLetter from "./pages/letter/WriteLetter";
import EmailLogin from "./pages/onboarding/EmailLogin";
import InformationFlow from "./pages/onboarding/information/InformationFlow";
import Login from "./pages/onboarding/Login";
import PhoneLogin from "./pages/onboarding/PhoneLogin";
import EditProfile from "./pages/profile/EditProfile";
import "./theme/variables.css";
import { UserContext } from "./util/BetterDatesApp";
import PreferencesFlow from "./pages/information/PreferencesFlow";
import SexualOrientationPreferences from "./pages/information/self/SexualOrientationPreferences";
import GenderInformation from "./pages/information/self/GenderInformation";
import LocationPreferences from "./pages/information/preferences/LocationPreferences";
import DescriptionPreferences from "./pages/information/preferences/DescriptionPreferences";
import GenderPreferences from "./pages/information/preferences/GenderPreferences";
import HeightInformation from "./pages/information/self/HeightInformation";
import ReligionInformation from "./pages/information/self/ReligionInformation";
import HeightPreferences from "./pages/information/preferences/HeightPreferences";
import ReligionPreferences from "./pages/information/preferences/ReligionPreferences";

export default function AppRouter() {
  const userContext = useContext(UserContext);
  const userState = userContext?.userState;
  const loggedIn = useMemo(() => userState?.loggedIn, [userState]);
  const isProfileComplete = useMemo(
    () => userState?.loggedIn && userState?.savedUser?.isProfileComplete,
    [loggedIn, userState],
  );

  const protectForCompleteProfile = (page: JSX.Element) => {
    if (loggedIn && isProfileComplete) {
      return page;
    } else {
      return <Redirect to={"/complete-profile"} />;
    }
  };

  const protectForLoggedOutProfile = (page: JSX.Element) => {
    if (!loggedIn) {
      return page;
    } else {
      return <Redirect to={"/"} />;
    }
  };

  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route
          exact
          path="/login"
          render={() => protectForLoggedOutProfile(<Login />)}
        />
        <Route
          exact
          path="/email-login"
          render={() => protectForLoggedOutProfile(<EmailLogin />)}
        />
        <Route
          exact
          path="/phone-login"
          render={() => protectForLoggedOutProfile(<PhoneLogin />)}
        />
        <Route
          exact
          path="/home"
          render={() => protectForCompleteProfile(<Home />)}
        />
        <Route
          exact
          path="/edit-profile"
          render={() => protectForCompleteProfile(<EditProfile />)}
        />
        <Route
          exact
          path="/write/:matchId/:partyTwoId"
          render={() => protectForCompleteProfile(<WriteLetter />)}
        />
        <Route
          exact
          path="/letters/:matchId/:partyTwoId"
          render={() => protectForCompleteProfile(<ListOfLetters />)}
        />
        <Route
          exact
          path="/letter/:matchId/:letterId"
          render={() => protectForCompleteProfile(<ReadLetter />)}
        />
        <Route
          exact
          path="/preferences"
          render={() => protectForCompleteProfile(<PreferencesFlow />)}
        />
        <Route
          exact
          path="/sexual-orientation"
          render={() =>
            protectForCompleteProfile(<SexualOrientationPreferences />)
          }
        />
        <Route
          exact
          path="/gender"
          render={() => protectForCompleteProfile(<GenderInformation />)}
        />
        <Route
          exact
          path="/change-location"
          render={() => protectForCompleteProfile(<LocationPreferences />)}
        />
        <Route
          exact
          path="/preferences/description"
          render={() => protectForCompleteProfile(<DescriptionPreferences />)}
        />
        <Route
          exact
          path="/preferences/gender"
          render={() => protectForCompleteProfile(<GenderPreferences />)}
        />
        <Route
          exact
          path="/height"
          render={() => protectForCompleteProfile(<HeightInformation />)}
        />
        <Route
          exact
          path="/preferences/height"
          render={() => protectForCompleteProfile(<HeightPreferences />)}
        />
        <Route
          exact
          path="/religion"
          render={() => protectForCompleteProfile(<ReligionInformation />)}
        />
        <Route
          exact
          path="/preferences/religion"
          render={() => protectForCompleteProfile(<ReligionPreferences />)}
        />
        <Route
          exact
          path="/complete-profile"
          render={() => {
            if (loggedIn && !isProfileComplete) {
              return <InformationFlow />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          exact
          path="/"
          render={() => {
            if (loggedIn && isProfileComplete) {
              return <Redirect to="/home" />;
            } else if (loggedIn && !isProfileComplete) {
              return <Redirect to="/complete-profile" />;
            } else {
              return <Redirect to="/login" />;
            }
          }}
        />
      </IonRouterOutlet>
    </IonReactRouter>
  );
}
