import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonLabel,
  IonModal,
  IonPage,
  IonPicker,
  IonPickerColumn,
  IonPickerColumnOption,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router";
import { JIBI_BASE_URL, PATCH } from "../../../util/ApiClient";
import { UserContext } from "../../../util/BetterDatesApp";
import { handleError } from "../../../util/error";
import { cmToFeetInches } from "../../../util/measurement-ext";
import RegistrationStepper from "../../../components/RegistrationStepper";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

export default function HeightInformation() {
  const userContext = useContext(UserContext);
  const history = useHistory();

  if (!userContext?.userState?.loggedIn) {
    return null;
  }
  const savedUser = userContext.userState.savedUser;

  const [selectedHeight, setSelectedHeight] = useState<number>(
    savedUser.user.height || 175,
  );
  const [inProgress, setInProgress] = useState(false);

  const modal = useRef<HTMLIonModalElement>(null);

  const heightOptions = Array.from({ length: 151 }, (_, i) => {
    const cm = 100 + i;
    return {
      text: `${cm} cm (${cmToFeetInches(cm)})`,
      value: cm,
    };
  });

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex flex-col gap-8 px-8 py-8">
            <div>
              <IonChip
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
                <IonLabel>Back</IonLabel>
              </IonChip>
            </div>
            <RegistrationStepper steps={2} completedUntil={0} />
            <div className="flex w-full flex-row items-start">
              <IonText className="text-2xl font-bold">
                What&apos;s your height?
              </IonText>
            </div>
          </header>
          <main className="h-full w-full">
            <div className="flex h-full w-full flex-col justify-between px-8 pb-8">
              <IonContent className="scrollable text-gray-800" scrollY={false}>
                <div className="mt-4 w-full">
                  <button
                    id="open-height-modal"
                    className="rounded-md bg-gray-100 px-4 py-3 text-left text-sm font-normal text-gray-700 shadow-sm transition-colors duration-200 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  >
                    {selectedHeight
                      ? `${selectedHeight} cm (${cmToFeetInches(selectedHeight)})`
                      : "Select your height"}
                  </button>
                </div>
              </IonContent>
              <div className="flex w-full flex-row justify-end">
                <IonButton
                  className="mt-8 h-9 w-20"
                  shape="round"
                  color="dark"
                  disabled={inProgress}
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setInProgress(true);
                    if (!selectedHeight) return;
                    patchUserHeight(selectedHeight, savedUser.token)
                      .then((result) => {
                        if (result.status !== 200) {
                          return Promise.reject(result);
                        }
                        const updatedUser = {
                          ...savedUser.user,
                          height: selectedHeight,
                        };
                        return userContext?.loginHook.saveUser({
                          ...savedUser,
                          user: updatedUser,
                        });
                      })
                      .then(() => history.replace("/preferences/height"))
                      .catch((e) => handleError(e, userContext))
                      .finally(() => {
                        setInProgress(false);
                      });
                  }}
                >
                  Next
                </IonButton>
              </div>
            </div>
          </main>
        </div>
      </div>

      <IonModal
        ref={modal}
        trigger="open-height-modal"
        className="ion-modal-sheet"
      >
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => modal.current?.dismiss()}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              onClick={() => modal.current?.dismiss(selectedHeight, "confirm")}
            >
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonPicker className="ion-picker-with-safe-margin">
          <IonPickerColumn
            value={selectedHeight}
            onIonChange={({ detail }) =>
              setSelectedHeight(Number(detail.value))
            }
          >
            {heightOptions.map((option) => (
              <IonPickerColumnOption key={option.value} value={option.value}>
                {option.text}
              </IonPickerColumnOption>
            ))}
          </IonPickerColumn>
        </IonPicker>
      </IonModal>
    </IonPage>
  );
}

function patchUserHeight(height: number, token: string) {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/height`,
    headers: {
      Authorization: token,
    },
    body: {
      height: height,
    },
  });
}
