import { IonButton, IonText } from "@ionic/react";

export default function Welcome(props: { onNext: () => void }) {
  return (
    <div className="flex h-full w-full flex-col justify-between px-8 pb-4">
      <IonText>
        Let&apos;s complete your profile, so it attracts your matches.
      </IonText>
      <div className="flex w-full flex-row justify-end">
        <IonButton
          className={"mt-8 h-9 w-20"}
          shape={"round"}
          color={"dark"}
          size={"small"}
          onClick={(e) => {
            e.preventDefault();
            props.onNext();
          }}
        >
          Next
        </IonButton>
      </div>
    </div>
  );
}
